import "./works.scss";

import back from "../../assets/icons/back.png"
import forward from "../../assets/icons/forward.png"
import shopify from "../../assets/icons/shopify.png"
import chrome from "../../assets/icons/chrome.png"
import appStore from "../../assets/icons/appStore.png"

import shopifyImg from "../../assets/images/shopify-2.JPG"
import chromeImg from "../../assets/images/janmang.JPG"
import appStoreImg from "../../assets/images/studentCard.png"

import { useState } from 'react'

export default function Works() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const data = [
  {
    id: "1",
    icon: shopify,
    title:"Shopify",
    desc:
    "The Store Setting, Editing, Adding Featured page and more!",
    note: "Access Password: thanks",
    img: shopifyImg,
    link: "https://pt8d0km70i6gkr0d-59011727511.shopifypreview.com"
  },
  {
    id: "2",
    icon: chrome,
    title:"Landing",
    desc:
    "This is description for the project.",
    note: "Access Password: thanks",
    img: chromeImg,
    link: "https://janmang2.com",
  },
  {
    id: "3",
    icon: appStore,
    title:"Application",
    desc:
    "This is description for the project.",
    note: "Access Password: thanks",
    img: appStoreImg,
    link: "https://studentmanage.laelgia.com/",
  },
]

const handleClick = (way)=>{
  way === "leftArrowImg" ? setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : (data.length - 1)) : setCurrentSlide(currentSlide < data.length - 1 ? currentSlide + 1 : 0)
}

  return (
    <div className="works" id="works">
      <div className="slider" style={{transform:`translateX(-${currentSlide * 84.5}vw)`}}>
        {data.map((d, idx) => (
        <div className="container" key={idx}>
          <div className="item">
            <div className="left">
              <div className="leftContainer">
                <div className="imgContainer">
                  <img src={d.icon} alt="smartPhone" />
                </div>
                <h2>{d.title}</h2>
                <p>
                  {d.desc}
                </p>
                <p className="infoNote">
                  {d.note}</p>
                <span onClick={()=> window.location.href=d.link}>VIDEO</span>
              </div>
            </div>
            <div className="right">
              <img src={d.img} alt="mobile01" />
            </div>
          </div>
        </div>
        ))}
      </div>
      <img src={back} className="arrow leftArrowImg" alt="back" onClick={()=>handleClick("leftArrowImg")}/>
      <img src={forward} className="arrow rightArrowImg" alt="forward" onClick={()=>handleClick()}/>
    </div>
  )
}
