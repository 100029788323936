import "./shopify.scss";
import shopifyImg from "../../assets/images/shopify.JPG";
import down from "../../assets/icons/down.png";
import { Typography } from "@mui/material";

const Shopify = () => {
  return (
    <div className="shopify" id="shopify">
      <div className="leftShop">
        <div className="shopLeftContainer">
          <img src={shopifyImg} alt="shopifyImg" />
        </div>
      </div>
      <div className="rightShop">
        <div className="rightRightContainer">
          <div className="wrapper">
            <Typography component="h2"  variant="body2"><span className="animatedFont">All-in-One</span> Technician</Typography>
            <Typography component="h1" variant="body1">Manage your Content</Typography>
          </div>
          <a href="#portfolio">
            <img src={down} alt="down" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Shopify
