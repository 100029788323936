import "./portfolio.scss";
import PortfolioList from "../portfolioList/PortfolioList";
import { useState, useEffect } from "react";
import {
  shopifyPortfolio,
  premiumPortfolio,
  webAppPortfolio,
  mobileAppPortfolio,
} from "./portfoliodata";

import ModalVideo from 'react-modal-video'
import { Typography } from "@mui/material";

export default function Portfolio() {
  const [selected, setSelected] = useState("shopify");
  const [data, setData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  function openModal(d) {
    if(d.videoId !== " "){
      setIsOpen(true);
    } else {
      setIsOpen(false);
      alert("The demo video is coming soon, Thanks!");
    }
  }

  const list = [
    {
      id: "shopify",
      title: "Shopify",
    },
    {
      id: "premium",
      title: "Premium Site",
    },
    {
      id: "webApp",
      title: "Web App",
    },
    {
      id: "mobileApp",
      title: "Mobile App",
    },
  ]

  useEffect(() => {
    switch(selected){
      case "shopify":
        setData(shopifyPortfolio)
        break;

        case "premium":
        setData(premiumPortfolio)
        break;

        case "webApp":
        setData(webAppPortfolio)
        break;

        case "mobileApp":
        setData(mobileAppPortfolio)
        break;

        default:
          setData(shopifyPortfolio);
    }
  }, [selected])

  return (
    <div className="portfolio" id="portfolio">
      <Typography variant="h1">Portfolio</Typography>
      <ul className="listCategory">
        {list.map((item, idx) => (
          <PortfolioList 
          title={item.title} 
          active={selected === item.id} 
          setSelected={setSelected}
          id={item.id}
          key={idx}
        />
        ))}
      </ul>
      <div className="container">  
        {data.map((d, idx)=> (
          <div className="item" key={idx}>
            <img src={d.img} alt={d.title} onClick={()=>openModal(d)}/>
            <h3 className="cateTitle">{d.title}</h3>
              <ModalVideo 
                channel='youtube' 
                isOpen={isOpen} 
                // videoId="myJOOIhh5eg" // 정상동작
                videoId={d.videoId} // 동작안함
                onClose={() => setIsOpen(false)} 
              /> 
          </div>
        ))}
      </div>
      {/* test purpose */}
      {isOpen ? <Typography className="modalTest">The modal is Opened</Typography> : null }

      {/* <iframe width="430" height="294" src="https://www.loom.com/embed/4f47760b4d0d4a208015a94a1f15db19" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>  */}
    </div>
  )
}
