

import shopifyPortfolio_01 from "../../assets/images/faqAccordion.png";
import shopifyPortfolio_02 from "../../assets/images/productDescriptionTabs.png";
import shopifyPortfolio_03 from "../../assets/images/reviewApp.png";
import shopifyPortfolio_04 from "../../assets/images/wholesaleInq.png";

import premiumPortfolio_01 from "../../assets/images/premiumSite_01.png";
import premiumPortfolio_02 from "../../assets/images/premiumSite_02.png";
import premiumPortfolio_03 from "../../assets/images/premiumSite_03.png";
import premiumPortfolio_04 from "../../assets/images/premiumSite_04.png";

import webAppPortfolio_01 from "../../assets/images/webAppPortfolio_01.png";
import webAppPortfolio_02 from "../../assets/images/webAppPortfolio_02.png";
import webAppPortfolio_03 from "../../assets/images/webAppPortfolio_03.png";
import webAppPortfolio_04 from "../../assets/images/webAppPortfolio_04.png";

import mobileAppPortfolio_01 from "../../assets/images/mobileAppPortfolio_01.png";
import mobileAppPortfolio_02 from "../../assets/images/mobileAppPortfolio_02.png";
import mobileAppPortfolio_03 from "../../assets/images/mobileAppPortfolio_01.png";
import mobileAppPortfolio_04 from "../../assets/images/mobileAppPortfolio_02.png";


export const shopifyPortfolio = [
  {
    id:1,
    title:"FAQ Accordion",
    img:shopifyPortfolio_01,
    vLink:"",
    videoId:" ",
  }, 
  {
    id:2,
    title:"Product Description Tabs",
    img:shopifyPortfolio_02,
    vLink:"",
    videoId:" ",
  }, 
  {
    id:3,
    title:"Review App",
    img:shopifyPortfolio_03,
    vLink:"",
    videoId:" ",
  }, 
  {
    id:4,
    title:"Wholesale Inquiry",
    img:shopifyPortfolio_04,
    vLink:"",
    videoId:" ",
  }, 
  
];
export const premiumPortfolio = [
  {
    id:1,
    title:"YouTube Janmang",
    img:premiumPortfolio_01,
    vLink:"",
    videoId:" ",
  }, 
  {
    id:2,
    title:"zomokzomok",
    img:premiumPortfolio_02,
    vLink:"",
    videoId:" ",
  }, 
  {
    id:3,
    title:"Gloworks",
    img:premiumPortfolio_03,
    vLink:"",
    videoId:" ",
  }, 
  {
    id:4,
    title:"Frankly Coffee",
    img:premiumPortfolio_04,
    vLink:"",
    videoId:" ",
  }, 
];

export const webAppPortfolio = [
  {
    id:1,
    title:"Memories",
    img:webAppPortfolio_01,
    vLink:"https://www.loom.com/share/4f47760b4d0d4a208015a94a1f15db19",
    videoId: "myJOOIhh5eg",
  }, 
  {
    id:2,
    title:"Trip Addvisor",
    img:webAppPortfolio_02,
    gif:"https://cdn.loom.com/sessions/thumbnails/4f47760b4d0d4a208015a94a1f15db19-with-play.gif",
    vLink:"https://www.loom.com/share/4f47760b4d0d4a208015a94a1f15db19",
    videoId:"myJOOIhh5eg",
  }, 
  {
    id:3,
    title:"Clan Member Card",
    img:webAppPortfolio_03,
    vLink:"https://www.loom.com/share/4f47760b4d0d4a208015a94a1f15db19",
    videoId: "myJOOIhh5eg",
  }, 
  {
    id:4,
    title:"Student Manage Card",
    img:webAppPortfolio_04,
    vLink:"https://www.loom.com/share/4f47760b4d0d4a208015a94a1f15db19",
    videoId: "myJOOIhh5eg",
  }, 
];

export const mobileAppPortfolio = [
  {
    id:1,
    title:"Bucket List",
    img:mobileAppPortfolio_01,
    link:"",
    videoId: " ",
  }, 
  {
    id:2,
    title:"FriendShip Test",
    img:mobileAppPortfolio_02,
    link:"",
    videoId: " ",
  }, 
  {
    id:3,
    title:"Bucket List",
    img:mobileAppPortfolio_03,
    link:"",
    videoId: " ",
  }, 
  {
    id:4,
    title:"FriendShip Test",
    img:mobileAppPortfolio_04,
    link:" ",
    videoId: " ",
  }, 
];

// export const brandingPortfolio = [
//   {
//     id:1,
//     title:"brandingPortfolio",
//     img:branding01,
//     link:"",
//   }, 
//   {
//     id:2,
//     title:"brandingPortfolio",
//     img:branding01,
//     link:"",
//   }, 
//   {
//     id:3,
//     title:"brandingPortfolio",
//     img:branding01,
//     link:"",
//   }, 
//   {
//     id:4,
//     title:"brandingPortfolio",
//     img:branding01,
//     link:"",
//   }, 
// ];
