import "./testimonials.scss";

import {featuredPortfolio} from "./testimonialsData"
export default function Testimonials() {
  return (
    <div className="testimonials" id="testimonials">
      <h1>Business Partner</h1>
      <div className="container">
        {featuredPortfolio.map((d,idx)=> (
          <div className={d.featured ? "card featured" : "card"} key={idx}>
            <div className="top" onClick={()=>window.location.href=d.siteLink}>
              <img src={d.icon} className="left" alt="businessType" />
              <img src={d.logo} className="user" alt="businessLogo" />
              <img src={d.link} className="right" alt="businessLink" />
            </div>
            <div className="center">
              {d.desc}
            </div>
            <div className="bottom">
              <h3>{d.title}</h3>
              <h4>{d.name}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
