import React from "react";
import "./contact.scss";
import teamWork from "../../assets/images/teamwork.jpg";
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import{ init, sendForm } from 'emailjs-com';
init("user_OevEKCLkb8TeJQT0JpS6B");

export default function Contact() {
  const { register, handleSubmit } = useForm();
  // const [message, setMessage] = useState();
  const [statusMessage, setStatusMessage] = useState("Message");
  const [isSent, setIsSent] = useState(false);

  const onSubmit = (data) => {
    // data.preventDefault();
    console.log("data:", data)
    generateContactNumber();
    sendForm('default_service', 'template_anr3xk1', '#contact-form')
      .then(function(response) {
      // ...
      setStatusMessage("Thanks for sending email. I will get back to you within 24 hours");
      setIsSent(true)
      setTimeout(()=> {
      }, 5000)
      document.getElementById("contact-form").reset();
    }, function(error) {
      // ...
      console.log(statusMessage);
      setIsSent(false)
      setStatusMessage("Failed to send message! Please try again later.");
      setTimeout(()=> {
      }, 5000)
    });

  };

  //Random number generator (#NNNNNN)
  const [contactNumber, setContactNumber] = useState("000000");

  const generateContactNumber = () => {
    const numStr = "000000" + (Math.random() * 1000000 | 0);
    setContactNumber(numStr.substring(numStr.length - 6));
  }

  return (
    <div className="contact "  id="contact" >
      <div className="left">
        <img src={teamWork} alt="teamWork" />
      </div>
      <div className="right">
        <h2>Contact</h2>
        <p className={"status-message " + (isSent ? "success" : null)}  id="message" >{statusMessage}</p>
        <form onSubmit={handleSubmit(onSubmit)} id='contact-form'>
        <input 
          type='text' 
          name='user_name' 
          placeholder='Name' 
          
          {...register("user_name", {
            max: 20,
            min: 3,
          })} 
        />
        <input type="email" name='user_email' placeholder="Email"
        {...register("user_email", {
          max: 20,
          min: 3,
        })}  />
          <textarea name='message' placeholder="Message"
          {...register("message", {
            max: 200,
            min: 3,
          })}  />
          <input type="submit" value="SEND" id="sendButton"/> 
          <input type='hidden' name='contact_number' value={contactNumber} />
          
          {/* {message && <span>Thank's, I'll reply ASAP</span>} */}
        </form>
      </div>
    </div>
  )
}
