import businessLink from "../../assets/icons/link.png";
import businessType01 from "../../assets/icons/real-estate.png";
import businessType02 from "../../assets/icons/cafe.png";
import businessType03 from "../../assets/icons/restaurant.png";

import businessLogo01 from "../../assets/icons/Gloworks.jpg";
import businessLogo02 from "../../assets/icons/frankly.png";
import businessLogo03 from "../../assets/icons/jj-joyLogo.png";


export const featuredPortfolio = [
  {
    id:1,
    name: "Enki Kim",
    title:"Owner",
    logo: businessLogo01,
    icon: businessType01,
    link: businessLink,
    siteLink: "https://startup01.laelgia.com/",
    desc: "We've migrated and renewed our business web page. Now it looks fancy and resposive for all devices. Great job! Han~!"
  }, 
  {
    id:2,
    name: "Ryan Choi",
    title:"Main Chef",
    logo: businessLogo02,
    icon: businessType02,
    link: businessLink,
    siteLink: "https://franklycoffee.com/",
    desc: "Han manages our web site includes the hosting/email service. He offers secured and simple task. No need to waste time to contact multiple agencies. ",
    featured: true,
  }, 
  {
    id:3,
    name: "Tonny Park",
    title:"Main Chef",
    logo: businessLogo03,
    icon: businessType03,
    link: businessLink,
    siteLink: "https://jj-joy.com",
    desc: "Small and Local business in Penticton. He takes care of our website with fair price. He also regulary update the site themes. Thanks!"
  }, 
];