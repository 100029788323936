import "./intro.scss";
import han from "../../assets/han.png";
import down from "../../assets/icons/down.png";
import { init } from 'ityped'
import { useEffect, useRef } from "react";

import { Typography } from "@mui/material";

export default function Intro() {

  const textRef = useRef();

  useEffect(() => {
    init(textRef.current, { 
      showCursor: true, 
      backDelay: 1500,
      backSpeed: 60,
      strings: ["Shopify Expert",  "Web Developer", "Content Creator"],
     });
  }, [])

  return (
    <div className="intro" id="intro">
      <div className="left">
        <div className="imgContainer">
          <img src={han} alt="han" />
        </div>
      </div>
        <div className="right">
          <div className="wrapper">
            <Typography component="h2" variant="body2">Hello World!</Typography>
            <Typography component="h1" variant="body1">Hoseok Han</Typography>
            <Typography component="h3" variant="body3">Freelance <span ref={textRef}></span></Typography>
          </div>
          <a href="#shopify">
            <img src={down} alt="down" />
          </a>
        </div>
    </div>
  )
}
