import "./topbar.scss";

import Email from '@mui/icons-material/Email';

export default function Topbar({menuOpen, setMenuOpen}) {
  return (
    // topbar 뒤에 공백 중요!
    <div className={"topbar " + (menuOpen && "active")}> 
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="mylogo">HHStudio</a>
          {/* <div className="itemcontainer">
            <PersonIcon className="icon" />
            <span>+1.250.328.0000</span>
          </div> */}
          <div className="itemcontainer">
          <a href="#contact">
            <Email className="icon" />
            <span>HAN@shopifystorehelp.com</span>
          </a>
          </div>
        </div>
        <div className="right">
          {/* 메뉴의 현재값을 반대로 바꿔준다 */}
          <div className="hamburger" onClick={()=>setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line2"></span>
            <span className="line3"></span>
          </div>
        </div>
      </div>
    </div>
  )
}
